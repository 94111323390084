import './vendor/infinite-scroll.pkgd';

const $scope = $('body')
  .attr('class')
  .replace('s-', '');


$(window).on('load resize', () => {
  /**
   * Header アクティブメニュー
   */
  $('.p-header__nav > a').each(function () {
    if ($scope === 'home') {
      $('.p-header__nav-item--home').addClass('is-active');
    } else if ($(this).attr('href').split('/')[1] === window.location.pathname.split('/')[1]) {
      $(this).addClass('is-active');
    } else {
      //
    }
  });
});

// header スクロールで縮小
$(function() {
	$(window).on('load scroll', function() {
		var scrollPos = $(this).scrollTop();
		if ( scrollPos > 150 ) {
			$('.l-header').addClass('is-scroll');
      $('.p-header').addClass('is-scroll');
		} else {
			$('.l-header').removeClass('is-scroll');
      $('.p-header').removeClass('is-scroll');
		}
	});
});

// header モバイル用メニュー表示
$('.p-header__button').on('click', function () {
  if ($(this).hasClass('active')) {
    $('body').css('overflow', '');
    $('body').removeClass('is-slidedown');
    $('.modal').hide();
    $(this).removeClass('active');
  } else {
    $('body').css('overflow', 'hidden');
    $('body').addClass('is-slidedown');
    $('.modal').show();
    $(this).addClass('active');
  }
});


/**
  * ページ上部に戻る
  */
 $('.p-footer__pagetop').hide();
 $(window).scroll(function(){
    var scrollPoint = $(this).scrollTop();
    (scrollPoint > 200) ? $('.p-footer__pagetop').fadeIn()
    :( scrollPoint < 200) ? $('.p-footer__pagetop').fadeOut():$('.p-footer__pagetop').show();
  });

  $('.p-footer__pagetop').on('click',function(){
    $('body,html').animate({
      scrollTop: 0
    }, 1000);
  });

 /*
const topBtn = $('.p-footer__pagetop');
topBtn.click(() => {
  $('body,html').animate({
    scrollTop: 0
  }, 1000);
  return false;
});
*/

/**
 * home main image
 */
if ($('.s-home').length > 0) {
  console.log('home');
  /*
  let swiper = new Swiper('.swiper', {
    loop: true,
    effect: 'fade',
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    speed: 2000,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    }
});
*/

  let swipeOption = {
    loop: true,
    effect: 'fade',
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    speed: 2000,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    }
  }
  new Swiper('.swiper', swipeOption);
  }


// news レスポンシブ
/*
if ($scope === 'news') {
  $(window).on('load', () => {
    $('.s-news__members').addClass('is-hide');

    $('.s-news__tabs-info').click(() => {
      $('.s-news__members').addClass('is-hide');
      $('.s-news__info').removeClass('is-hide');

      $('.s-news__tabs-member').addClass('is-hide');
      $('.s-news__tabs-info').removeClass('is-hide');
    });

    $('.s-news__tabs-member').click(() => {
      $('.s-news__members').removeClass('is-hide');
      $('.s-news__info').addClass('is-hide');

      $('.s-news__tabs-member').removeClass('is-hide');
      $('.s-news__tabs-info').addClass('is-hide');
    });
  });
}
*/


// 次のお知らせを読み込む
/*
$('.s-news__info .s-news__load').infiniteScroll({
  // options
  path: '.s-news__info .p-nav__next',
  button: '.s-news__info .p-nav__next-button',
  append: '.s-news__list',
  scrollThreshold: false,
  history: false,
});

$('.s-news__members .s-news__load').infiniteScroll({
  // options
  path: '.s-news__members .p-nav__next',
  button: '.s-news__members .p-nav__next-button',
  append: '.s-news__list',
  scrollThreshold: false,
  history: false,
});
*/

if ($('.s-recruits').length > 0) {

  var main_loop = true;
  var thumbs_loop = true;
  var thumbs_loop_sp = true;
  var thumbs_slidesPerView = 3;
  var thumbs_centeredSlides = true;
  if ($('.gallery-slider .swiper-slide').length == 1) {
    main_loop = false;
    thumbs_loop = false;
    thumbs_loop_sp = false;
  } else if ($('.gallery-slider .swiper-slide').length == 2) {
    thumbs_loop = false;
    thumbs_loop_sp = false;
    thumbs_centeredSlides = false;
  } else if ($('.gallery-slider .swiper-slide').length == 3) {
    thumbs_loop = false;
    thumbs_centeredSlides = false;
  }

  //サムネイル
  var thumbs = new Swiper ('.gallery-thumbs', {
    slidesPerView: 2,
    spaceBetween: 10,
    loop: thumbs_loop_sp,
    slideToClickedSlide: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      780: {
        slidesPerView: thumbs_slidesPerView,
        centeredSlides: thumbs_centeredSlides,
        loop: thumbs_loop,
      }
    },
  });

  //メイン
  var slider = new Swiper ('.gallery-slider', {
    slidesPerView: 1,
    centeredSlides: true,
    loop: main_loop,
    thumbs: {
      swiper: thumbs
    }
  });
}

/* 売買物件　詳細 */
if ($('.s-sales').length > 0) {

  var main_loop = true;
  var thumbs_loop = true;
  var thumbs_loop_sp = true;
  var thumbs_slidesPerView = 3;
  var thumbs_centeredSlides = true;
  if ($('.gallery-slider .swiper-slide').length == 1) {
    main_loop = false;
    thumbs_loop = false;
    thumbs_loop_sp = false;
  } else if ($('.gallery-slider .swiper-slide').length == 2) {
    thumbs_loop = false;
    thumbs_loop_sp = false;
    thumbs_centeredSlides = false;
  } else if ($('.gallery-slider .swiper-slide').length == 3) {
    thumbs_loop = false;
    thumbs_centeredSlides = false;
  }

  //サムネイル
  var thumbs = new Swiper ('.gallery-thumbs', {
    slidesPerView: 2,
    spaceBetween: 10,
    loop: thumbs_loop_sp,
    slideToClickedSlide: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      780: {
        slidesPerView: thumbs_slidesPerView,
        centeredSlides: thumbs_centeredSlides,
        loop: thumbs_loop,
      }
    },
  });

  //メイン
  var slider = new Swiper ('.gallery-slider', {
    slidesPerView: 1,
    centeredSlides: true,
    loop: main_loop,
    thumbs: {
      swiper: thumbs
    }
  });

}